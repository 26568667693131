.students-area{
    display: flex;
    flex: 1 2;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.students-area-top{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.students-table-area{
    flex: 1 2;
}

.student-table-container{
    overflow-x: auto;
}

.students-search-bar{
    flex: 1 2;
    background: #EBF0F7;
    border: 0;
    outline: 0;
}

.students-search-bar-area{
    display: flex;
    min-width: 300px;
    height: 35px;
    flex: 1 2;
    border-radius: 5px;
    overflow: hidden;
}

.students-search-button{
    width: 45px;
    background: #003C89;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}