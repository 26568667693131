.module{
    width: 100%;
    min-height: 50px;
    height: auto;
    background-color: rgb(1, 26, 93);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    overflow: hidden;
}

.module-container{
    width: 100%;
    height: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
    position: relative;
}

.module-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.module-header{
    display: flex;
    margin-left: 17px;
    margin-right: 21px;
    min-height: 37px;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.module-info {
    flex: 1 2;
    display: flex;
    gap: 10px;
    align-items: center;
}

.module-checkbox{
    width: 25px;
    height: 25px;
}

.h1{
    flex: 1 2;
    font-size: 24px;
    font-family: 'Manrope';
    font-weight: 700;
    color: rgb(255, 255, 255);
}

.dropdown-open{
    width: 16px;
    height: 10px;
}

.dropdown-closed{
    width: 16px;
    height: 10px;
    transform: rotate(180deg);
}

.index{
    width: 37px;
    height: 37px;
    background: #12c5ad;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    font-family: 'Manrope';
    font-weight: 700;
    color: white;
}

.module-footer{
    position: absolute;
    background-color: orange;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    padding-bottom: 8px;
}

.cases{
    display: flex;
    margin-left: 17px;
    margin-right: 13px;
    margin-bottom: 16px;
    gap: 8px;
    flex-direction: column;
}

.case {
    padding-left: 15px;
    padding-right: 15px;
    background: #d2d5e6;
    border-radius: 5px;
    width: auto;
    min-height: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.play-button{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.h2{
    flex: 1 2;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 300;
}

.hide{
    display: none;
}

.add-case{
    height: 55px;
}
.case-buttons{
    background-color: #adbed4;
    display: flex;
}

.case-dropdown select {
    background-color: #EBF2FC;
    width: 180px;
    height: 30px;
    border: none;
    font-size: 17px;
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}
  .button-container{
      display: flex;
      gap: 5px;
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 20px;
      font-style: bold;
}
  .case-edit-container{
    display: flex;
    align-items: center;
    top: 68px;
    gap: 5px;
    flex-wrap: wrap;
}
  .score{
    background: url(../../../assets/images/score-ellipse.png) no-repeat;
    background-position: 8px 1px;
    width: 55px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #DB8F00;;
}
