.loading-background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotate {
    animation: rotation 2s;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .linear {
    animation-timing-function: linear;
  }

  .infinite {
    animation-iteration-count: infinite;
  }

  .animated-logo {
    animation: rotation 2s linear infinite;
  }