.student-table-header{
    background: #197DFF;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.student-table-cell{
    background: #EBF2FC;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
}

.student-table-item{
    padding: 5px 10px;
    text-align: center;
}

#info{
    visibility: hidden;
}

table {
    border-collapse: collapse;
}

.menu-text{
    color: #EBF2FC;
    display: flex;
    flex: 1 2;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    padding-right: 15px;
    justify-content: end;
    align-items: center;
    cursor: pointer;
}