.footer-layout{
    display: flex;
    justify-content: center;
}

.footer{
    display: flex;
    text-align: center;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}