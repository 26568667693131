.grow-wrap {
    display: grid;
    margin-bottom: 20px;
}

.grow-wrap::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
}

.grow-wrap > textarea {
    resize: none;
    overflow: hidden;
}

.grow-wrap > textarea, .grow-wrap::after {
    background: #EBF2FC;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 300;
    line-height: 23.44px;
    padding: 15px;
    border-radius: 5px;
    border: 0;
    grid-area: 1 / 1 / 2 / 2;
}

.grow-wrap > textarea:focus{
    border: 0;
    outline: none;
}

.block-title-select{
    flex: 1 2;
    height: 50px;
    border-radius: 10px;
    border-width: 0;
    margin-bottom: 5px;
    color: #003C89;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    background-color: #ffffff;
}

.block-title-select:hover{
    border-width: 2px;
}

.block-title-container{
    display: flex;
    align-items: center;
}