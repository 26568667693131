.bar {
    display: flex;
    height: 61px;
    top: 0px;
    left: 0px;
    right: 5px;
    background: #003C89;
    align-items: center;
    padding: 0 15px;
}

.area{
    height: calc(100vh - 61px);
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #f0f2f5;
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
}
.dropdown {
    display: inline-block;
    width: 38.33px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    width: 60px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    text-align: center;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  } 
.area::-webkit-scrollbar {
    width: .4em;
}
   
.area::-webkit-scrollbar-thumb {
    background-color: #003C89;
}

.bar > .logo{
    cursor: pointer;
    height: 70%;
}
.accountIcon{
    width: 38.33px;
    height: 38.33px;
    top: 11px;
    right: 10px;
}
.searchbar {
    min-height: 41px;
    margin-left: 225px;
    margin-right: 55px;
    margin-top: 8px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}
.searchbar > input{
    width: auto;
    min-height: 41px;
    border-radius: 4px;
    border: 0;
    outline: 0;
    background: #EBF0F7 url(../../assets/images/lens.png) no-repeat;
    background-position: right 10px top 8px;
    padding-right: 40px;
}

.welcomeArea {
    width:100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1 2 ;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(221, 231, 231);
    font-weight: 700;
    font-size: 35px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgb(235, 238, 237) 0%, rgb(72, 219, 187) 35%, rgb(21, 76, 158) 100%);
    height: 100%;
}
.course-button{
    cursor: pointer;
}
.welcomeArea > .lastcourse{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.lastcourse > h1{
    font-family: 'Manrope';
    width: calc(100%-46px);
    height: auto;
    top: 32px;
    font-weight: 700;
    font-size: 34px;     
    margin-top: 0;
}

.lastcourse > h1 > span{
    color: #197DFF;
}

.videoThumbnail{
   display: flex;
   justify-content: center;
   align-items: center;
}

.lastcourse > .videoThumbnail{
    position: relative;
}

.videoThumbnail > div{
    width: 100%;
    max-width: 500px;
    position: relative;
}

.videoThumbnail > .playButton {   
    border: none;
    position: absolute;
    right: 26px;
    bottom: 20px;
    cursor: pointer;
    max-width: 50px;
}

.videoThumbnail > h2 {
    width: calc(100% - 142px);
    min-height: 66px;
    border: none;
    position: absolute;
    margin-bottom: 0;
    left: 26px;
    bottom: 20px;
    font-family: 'Manrope';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    display: flex;
    align-items: flex-start;
    color: white;
}

.welcomeArea > .lastcourses{
    min-width: 380px;
    max-height: 431px;
    right: 0;
    padding-top: 94px;
    flex: 1 2 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-grid-container {
    /*display: grid;
    grid-template-columns: auto;*/
    gap: 11px;
    flex: 1 2 0;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
}
.vertical-grid-container > h2{
    font-family: 'Manrope';
    width: 100%;
    height: 37px;
    font-weight: bold;
    font-size: 22px;    
    margin-top: 0;
    margin-bottom: 0;
}
.vertical-grid-container > .course {
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
    height: 103px;  
    display: flex;   
    overflow: hidden;
    cursor: pointer;
}
.course > .textContainer{ 
    flex: 1 2 0;
    font-family: 'Manrope';    
    font-style: normal;
    font-weight: 700;  
    size: 22px;  
    padding-top: 10px; 
    padding-right: 17px;
    padding-bottom: 5px;
    display: flex;
    align-items:center;
    padding-left: 15px;
}
 .course > .img-course{
    width: 140px;
}
.courses{
    width: 100vw;
    min-height: 643px;
    background-color: rgb(255, 255, 255);
    padding-top: 25px;
    font-size: 18px;
}
.horizontal-grid-container{
    margin-top: 0px;
    margin-right: 50px;
    height: 500px;
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;
    flex-direction: row;
    display: flex;    
}
.horizontal-grid-container::-webkit-scrollbar {
    height: 1em;
}
   
.horizontal-grid-container::-webkit-scrollbar-thumb {
    background-color: #003C89;
    border-radius: 5px;
}
.horizontal-grid-container > .courseContainer{    
    width: 386px;
    height: 455px;
    box-shadow: 5px 5px 5px 5px #aaaaaa;
    margin-top: 10px;
    margin-right: 18px;
    min-width: 386px;
    border-radius: 15px;
}
.courseContainer > .courseImage{
    width: 100%;
    min-height: 210px;
    max-height: 210px;
    border-radius: 15px;
}
.courseContainer > .courseTextTitle {
    width: 351px;
    height: 67px;
    margin-top: 15px;
    margin-left: 17px;
}
.courseTextTitle > div{
    font-family: 'Manrope';
    font-weight: 700;
    size: 24px;
}
.courseContainer > .students{
    background: url(../../assets/images/students_icon.png) no-repeat;
    background-size: 25.83px 25.83px;
    width: 351px;
    height: 27px;
    margin-top: 15px;
    margin-left: 17px;    
}
.students > div{
    font-family: 'Roboto';
    font-weight: 400;
    size: 18px;
    padding-left: 30px;
    padding-top: 3px;
    color: #8F5D00;
}
.courseContainer > .hours{
    background: url(../../assets/images/clock.png) no-repeat;
    background-size: 25.83px 25.83px;
    width: 351px;
    height: 27px;
    margin-top: 15px;
    margin-left: 17px;
}
.hours > div{
    font-family: 'Roboto';
    font-weight: 400;
    size: 18px;
    padding-left: 30px;
    padding-top: 3px;
    color: #8F5D00;
}
.courseContainer > .buttonCourse{
    background-color: #197DFF;
    width: 351px;
    height: 50px;
    margin-top: 15px;
    margin-left: 17px;
    border-radius: 10px;
    cursor: pointer;
}
.buttonCourse > div{
    font-family: 'Manrope';
    font-weight: 700;
    size: 24px;
    color: #ffffff;
    text-align: center;
    padding-top: 14px;
}
.react-tabs__tab--selected{
    border-color: #003C89 !important;
}
.tab{
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 400px;
    padding:10px 20px;
    margin:0px 5px;
    border-bottom: 5px solid #aaa;
    display:inline-block;
    transition: 0.25s ease;
    cursor: pointer;
}
.demoButton{
    width: 250px;
    height: 70px;
    background-color: #197DFF;
    border-radius: 7px;
}
.demoButton > div{
    background: url(../../assets/images/edit_icon.png) no-repeat;
    background-size: 25px 25px;
    background-position: 50px 20px;
    font-family: 'Manrope';
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding-top: 17px;
    color: white;    
    
}
.grid-table-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    padding: 10px;
    max-width: 94%;
  }
  .grid-table-container > input {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
  .mycourses{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  .mycourses > .myCourseContainer{
    border: 1px solid rgba(141, 141, 141, 0.8);
    box-shadow: 5px 5px 5px #aaaaaa;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 20px;
    max-width: 350px;
    min-width: 350px;
  }
  .myCourseContainer > .text-mycourses-container{
    width: auto;
    padding: 0;
    height: 130px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }
  .text-mycourses-container > div{
    font-family: 'Manrope';
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  .myCourseContainer > .button-mycourses-container{
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    height: 50px;
    background-color: #197DFF;
    border-radius: 7px;
    cursor: pointer;
    
  }
  .button-mycourses-container > div{
    font-family: 'Manrope';
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding-top: 10px;
    color: white;   
  }
  .main-tabs{
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
}
.main-tabs-title{
    font-family: 'Manrope';
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    padding-top: 29px;
    margin-bottom: 13px;
}
.grid-main-tabs{
    flex-direction: column;
    display: flex;
    position: relative;
}
.left-arrow{
    background-color: #197DFF;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0px;
    top: 260px;
    cursor: pointer;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    gap: 9px;
}
.right-arrow{
    background-color: #197DFF;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0px;
    top: 260px;
    cursor: pointer;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.right-arrow > img {
    width: 25px;
    height: 40px;
}
.left-arrow > img{
    width: 25px;
    height: 40px;
}

@media (max-width:920px){
    .grid-main-tabs > .left-arrow{
        display: none !important;
    }
    .grid-main-tabs > .right-arrow{
        display: none !important;
    }
}

.add-myCourseContainer{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 20px;
    cursor: pointer;
    gap: 10px;
    width: 392.98px;
    height: 237.94px;
}
.add-myCourseContainer:hover{
    background-color: #eeeded;
}
.copyright{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}
.copyright > .content-copyright{
    width: auto;
    height: auto;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
}
.content-copyright > div{
    color: #8181817e;
}
.log-tab{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.home-area{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d3d6d9;
}
.button-subscribe{
    margin: 1.5rem auto;
    color: #fff;
    background: #011a5d;
    border: none;
    font-weight: 700;
    font-size: 16px;
    padding: 0.8rem;
    border-radius: 10px;
    width: 100%;
    max-width: 650px;
    cursor: pointer;
}
.home-intro{
    display: flex;
    flex-direction: row;
    max-width: 1500px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    margin-top: 50px;
}
.home-up{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    flex: 1 2;
    max-width: 1500px;
}
.home-video{
    display: flex;
    height: 450px;
    width: 500px;
    border: none;
}
.home-first-area{
    margin-top: 25px;
    display: flex;
    flex: 1 2;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #889cd2;
}
.home-second-area{
    display: flex;
    flex: 1 2;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #011a5d;
    padding-top: 120px;
    width: 100%;
}
.what-is-FUVE{
    display: flex;
    flex-direction: row;
    gap: 55px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 2;
    max-width: 1800px;
}
.home-third-area{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 25px;
    background: linear-gradient(150deg, rgba(18,49,131,255) 35%, rgba(143,169,218,255) 65%, rgba(229,229,229,255) 100%);
}
.home-grid{
    display: flex;
    flex-direction: row;
    flex: 1 2;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}
.home-grid-card{
    background-color: #2d2439;
    border-radius: 10px;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
}
.home-four-area{
    margin-top: 150px;
    display: flex;
    flex: 1 2;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;  
    max-width: 1500px;  
}
.learn-fuve{
    background: #4565ba;
    border-radius: 20px;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 150px;
    position: relative;
    flex: 1 2;
}
@media (max-width:900px){
    .home-intro{
        max-width: 900px;;
    }
}
/*Basic structure of slider*/
.slider-container{
    overflow: hidden;
    max-width: 500px; 
    display: flex;
    width: 500px;
}

/*Area of images*/
.slider-image{
    display: flex;
    animation: slide 10s infinite;
}

.slider2-image{
    display: flex;
    animation: slide2 10s infinite;
}

/*Animation activated by keyframes*/
@keyframes slide{
    0%{
        transform: translateX(0);
    }
    33%{
        transform: translateX(-100%);
    }
    66%{
        transform: translateX(-200%);
    }
}

@keyframes slide2{
    0%{
        transform: translateX(0);
    }
    50%{
        transform: translateX(-100%);
    }
}

.home-video-first{
    width: 100%;
}

.circle-text{
    font-size: 20px;
    font-weight: 400; 
    border: 1px solid #889cd2;
    border-radius: 25px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 355px;
}

  .contact-us{
    color: white;
    font-family: 'Montserrat';
    font-weight: 700;
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-us-container{
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }
  .contact-us-form{
    display: flex;
    flex-direction: column;
    flex: 1 2;
    justify-content: center;
    align-items: center;
    color: white;  
    max-width: 500px;
    padding-right: 50px;
    gap: 15px;
}
.login{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    max-height: 300px;
    gap: 10px;
}
.input-form{
    width: 100%;
}
.input-form input{
    width: 98%;
    height: 50px;
    border: 0;
    border-radius: 20px;
}
.button-blue{
    background-color: #011a5d;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
}
.container {
    border-bottom: 1px solid #889cd2;
    height: 1rem;
    display: flex;
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .container {
      height: 2.5rem;
    }
  }

  .circle-line{
    display: flex;
    width: 100%;
  }
  .relative-img{
    width: 100%;
    max-width: 650px;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .info-button{
    background-color: #4565ba;
    max-width: 300px;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    border-radius: 10px;
    text-decoration: none;
    color: #fff;
  }
  .info-button:hover{
    background-color: #2f4275;
  }
  .video-home{
    display: flex;
    width: 100%;
  }
  .text-card{
    position: absolute;
    bottom: 0;
    display: flex;
    max-height: 100px;
    padding: 5px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.637) 0%, rgba(0, 0, 0, 0.637) 35%, rgba(0, 0, 0, 0.534) 100%);
    border-radius: 25px;
  }
  .text-card > div {
    display: flex;
    font-size: 25px;
    color: white;
  }
  .section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
form {
    margin: 0 0 1.45rem;
    padding: 0;
}
.form-styling {
    background: #f5f5f5;
    border: thin;
    border-radius: 15px;
    height: 35px;
    margin-bottom: 20px;
    padding-left: 15px;
    width: 100%;
}
button, input, optgroup, select, textarea {
    font: inherit;
    margin: 0;
    color: #011a5d;
}
button, input {
    overflow: visible;
}
.button:hover {
    background-color: rgb(1, 26, 93);
}
.button-cta {
    background-color: rgb(46, 73, 148);
}
.button {
    border: none;
}
.button {
    border-radius: 40px;
    color: #fff;
    font-family: var(--font-headers);
    font-weight: 700;
    min-width: 220px;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    transition: all .3s ease;
}
.simulator-title{
    background-color: white;
    padding: 10px;
    font-size: 35px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 35px;
    align-items: center;
    max-width: 500px;
    width: 100%;
    bottom: -25px;
}
.simulations-image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #011a5d;
    width: 100%;
    padding: 50px 0;
    gap: 150px;
}

@media (max-width: 1000px) {
    .simulations-image {
      flex-wrap: wrap;
    }
  }

.simulation-img:hover{
    transform: scale(1.15); 
    cursor: pointer;
}