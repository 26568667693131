.login-title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lineText {
    overflow: hidden;
    text-align: center;
}

.lineText:before,
.lineText:after {
    background-color: rgb(75, 75, 75);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
  
.lineText:before {
    right: 0.5em;
    margin-left: -50%;
}
  
.lineText:after {
    left: 0.5em;
    margin-right: -50%;
}